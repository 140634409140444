"use client";

import * as Sentry from "@sentry/nextjs";
import React from "react";
import Link from "next/link";
import NextError from "next/error";

import { Button, buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { RotateCw } from "lucide-react";

export default function GlobalError({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}) {
	React.useEffect(() => {
		console.error(error);
		Sentry.captureException(error);
	}, [error]);

	return (
		<html>
			<body>
				<div className="grid min-h-[70vh] place-content-center gap-4 text-center">
					<h2 className="font-semibold text-primary">There was an issue!</h2>
					<p>
						Please try again or contact{" "}
						<a href="mailto:support@examine.com" className="link">
							support
						</a>{" "}
						if the issue persists.
					</p>
					<div className="flex justify-center gap-6">
						<Button className="" onClick={() => reset()}>
							Try again
							<RotateCw size={16} />
						</Button>
						<Link href="/" className={cn(buttonVariants({ variant: "outline" }))}>
							Go back home
						</Link>
					</div>
					<p className="text-lg italic opacity-5">{error?.message}</p>
				</div>
				<NextError statusCode={0} />
			</body>
		</html>
	);
}
